<template>
  <div>
    <el-table :data="tableData" border style="width: 100%">
      <template slot="empty">
        <div class="noData">ไม่พบข้อมูลที่ค้นหา</div>
      </template>
      <el-table-column label="ลำดับ" width="70" align="center">
        <template slot-scope="scope">
          {{ scope.$index + skip + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="name" header-align="center" label="ชื่อ">
      </el-table-column>
      <el-table-column prop="surname" header-align="center" label="นามสกุล">
      </el-table-column>
      <el-table-column prop="email" header-align="center" label="อีเมล">
      </el-table-column>
      <el-table-column
        prop="corporateNumber"
        align="center"
        label="เลขนิติบุคคล"
      >
      </el-table-column>
      <el-table-column
        prop="total"
        header-align="center"
        label="คะแนนประเมินเฉลี่ย"
      >
        <template slot-scope="scope"
          ><div class="text-center">
            {{ scope.row.total | dotRoundDown }} คะแนน
          </div></template
        >
      </el-table-column>
      <el-table-column
        prop="createdAt"
        header-align="center"
        label="วันที่และเวลาที่ประเมิน"
      >
        <template slot-scope="scope"
          ><div class="text-center">
            {{ scope.row.createdAt | dateTimeTh }}
          </div></template
        >
      </el-table-column>
      <el-table-column align="center" width="120"
        ><template slot-scope="scope"
          ><div>
            <router-link
              :to="`/view-feedback/${scope.row.userId}/${scope.row.companyId}`"
              target="_blank"
              class="mg-r-7"
            >
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-view"
                class="font-12 color-view"
                circle
              ></el-button>
            </router-link>
            <el-button
              @click="$emit('deleteUser', scope.row)"
              type="danger"
              size="mini"
              icon="el-icon-delete"
              class="font-12 color-delete"
              circle
            ></el-button></div
        ></template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    tableData: {
      typeof: Array,
      default: []
    },
    skip: {
      default: String
    }
  }
};
</script>