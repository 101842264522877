<template>
  <DashboardAdminTemplate>
    <div class="pd-x-5 pd-b-3">
      <el-row :gutter="20">
        <el-col :span="12" :sm="16" :offset="0"
          ><h2 class="mg-r-5">
            แบบประเมินความพึงพอใจในการใช้งานระบบประเมิน TERAK
          </h2></el-col
        >
        <el-col
          :span="12"
          :sm="8"
          :offset="0"
          class="text-right is-flex ai-center js-end"
        >
          <el-tooltip
            class="item"
            effect="dark"
            content="ค้นหาชื่อ นามสกุล อีเมล เลขนิติบุคคล"
            placement="bottom-end"
          >
            <el-button class="pd-0 mg-t-3">
              <el-input
                clearable
                placeholder="ค้นหาชื่อ นามสกุล อีเมล เลขนิติบุคคล"
                @input="searchAssessment(600)"
                v-model="inputFilter"
                v-if="activeName == 'person'"
              >
              </el-input>
            </el-button>
          </el-tooltip>
          <el-dropdown
            trigger="click"
            @command="handleCommand"
            class="mg-t-3 mg-l-5"
          >
            <el-button
              type="primary"
              style="padding: 9px 20px"
              v-loading.fullscreen.lock="fullscreenLoading"
            >
              Export ความพึงพอใจ<i
                class="el-icon-arrow-down el-icon--right"
              ></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="all">Export ทั้งหมด</el-dropdown-item>
              <el-dropdown-item command="manange"
                >เลือกช่วงวันที่ Export</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </el-row>
      <div v-loading="loading">
        <el-tabs v-model="activeName">
          <el-tab-pane
            label="สรุปผลประเมินการใช้งานระบบประเมิน TERAK"
            name="all"
          >
            <div v-if="activeName == 'all'">
              <Complacency :tableData="tableData" />
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="`สรุปผลประเมินตามรายบุคคล (${feedTotal})`"
            name="person"
          >
            <div class="pd-b-2" v-if="activeName == 'person'">
              <individual
                :skip="skip"
                :tableData="tableUser"
                @deleteUser="deleteUser"
                class="feedTotal"
              />
              <div class="mg-y-3">
                <!-- :page-sizes="[15, 30, 50, 100, 500]" -->
                <div class="block text-center">
                  <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-sizes="[15, 30, 50, 100, 500]"
                    :page-size="limit"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="feedTotal"
                  >
                  </el-pagination>
                </div>
              </div>
              <!-- <div class="text-center mg-t-3">
                  <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="feedTotal"
                    :page-size="10"
                    @current-change="changeSize"
                  >
                  </el-pagination>
                </div> -->
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <el-dialog
        width="40%"
        class="text-center font-32 exportBydate"
        title="เลือกช่วงเวลาในการ Export แบบประเมินความความพึงพอใจ"
        :visible.sync="dialogFormVisible"
      >
        <el-form
          label-position="top"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
          :model="form"
        >
          <el-form-item
            label="กรุณาเลือกวันที่เริ่มต้น - วันที่สิ้นสุด"
            style="text-align: initial"
            prop="dateComplacency"
            class="is-required"
          >
            <el-date-picker
              v-model="form.dateComplacency"
              type="daterange"
              range-separator="ถึง"
              start-placeholder="วันที่เริ่มต้น"
              end-placeholder="วันที่สิ้นสุด"
              class="w-100"
            >
            </el-date-picker>
          </el-form-item>
          <el-button
            type="primary"
            size="default"
            class="mg-t-4"
            @click="exportCustom"
            >Export ความพึงพอใจ</el-button
          >
        </el-form>
      </el-dialog>
    </div>
  </DashboardAdminTemplate>
</template>
<script>
import DashboardAdminTemplate from "@/template/DashboardAdminTemplate";
import individual from "@/components/feedback/IndiviDualAssessment";
import Complacency from "@/components/feedback/Complacency";
import moment from "moment";
import { HTTP } from "@/service/axios";
import LoginFormVue from "../components/Signup/LoginForm.vue";

export default {
  components: {
    DashboardAdminTemplate,
    individual,
    Complacency
  },
  computed: {
    assessment() {
      return this.$store.state.assessment;
    }
  },
  data() {
    var checkDated = (rule, value, callback) => {
      if (this.form.dateComplacency.length == 0) {
        return callback(new Error("กรุณาเลือกวันที่เริ่มต้น - วันที่สิ้นสุด"));
      } else {
        return callback();
      }
    };
    return {
      currentPage: 1,
      form: {
        dateComplacency: []
      },
      filterTime: null,
      fullscreenLoading: false,
      dialogFormVisible: false,
      loading: false,
      inputFilter: "",
      activeName: "person",
      tableData: [],
      tableUser: [],
      search: "all",
      page: 1,
      limit: 15,
      skip: 0,
      feedTotal: 0,
      assess: ["one", "two", "three", "four", "five", "six"],
      rules: {
        dateComplacency: [{ validator: checkDated, trigger: "blur" }]
      }
    };
  },
  watch: {
    activeName() {
      if (this.activeName == "all") {
        this.getAvg();
      }

      if (this.activeName == "person") {
        this.getAssessment();
      }
    }
  },
  created() {
    if (typeof this.$route.params.tab !== "undefined") {
      this.activeName = this.$route.params.tab;
      this.$router.push("/user-feedback").catch(() => {});
    }
  },
  async mounted() {
    await this.getAssessment();
    await this.getAvg();
  },
  methods: {
    async handleSizeChange(val) {
      this.limit = val;
      this.handleCurrentChange(1);
    },
    async handleCurrentChange(val) {
      this.currentPage = val;
      this.skip = val == 1 ? 0 : (val - 1) * this.limit;
      this.getAssessment();
    },
    handleCommand(command) {
      command == "all"
        ? this.exportComplacency()
        : (this.dialogFormVisible = true);
    },
    exportComplacency() {
      this.fullscreenLoading = true;
      let obj = {
        start: "",
        end: ""
      };
      HTTP.post(`export/complacency`, obj)
        .then(res => {
          // console.log(res);
          if (res.data.success) {
            var name = "_blank";
            var specs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"];
            const url = process.env.VUE_APP_IMAGE_SYSTEM_URL + res.data.obj;
            //const win = window.open(url, specs);
            var createA = document.createElement("a");
            createA.setAttribute("href", url);
            createA.setAttribute("target", "_blank");
            setTimeout(() => {
              createA.click();
            }, 400);
            this.$message({
              type: "success",
              message: "ส่งออกข้อมูลสำเร็จ"
            });
            this.fullscreenLoading = false;
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        });
    },
    exportCustom() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          // console.log(valid);
          this.fullscreenLoading = true;
          this.dialogFormVisible = false;
          // console.log(this.form.dateComplacency);
          let obj = {
            start: moment(this.form.dateComplacency[0]).format("yyyy-MM-DD"),
            end: moment(this.form.dateComplacency[1]).format("yyyy-MM-DD")
          };
          HTTP.post(`export/complacency`, obj)
            .then(res => {
              // console.log(res);
              if (res.data.success) {
                var name = "_blank";
                var specs = [
                  "fullscreen=yes",
                  "titlebar=yes",
                  "scrollbars=yes"
                ];
                const url = process.env.VUE_APP_IMAGE_SYSTEM_URL + res.data.obj;
                //const win = window.open(url, specs);
                var createA = document.createElement("a");
                createA.setAttribute("href", url);
                createA.setAttribute("target", "_blank");
                setTimeout(() => {
                  createA.click();
                }, 400);
                this.$message({
                  type: "success",
                  message: "ส่งออกข้อมูลสำเร็จ"
                });
                this.fullscreenLoading = false;
              }
            })
            .catch(e => {
              if (e == "Error: Request failed with status code 401") {
                this.checkAuth();
              } else {
                if (e != "Error: timeout of 120000ms exceeded") {
                  this.alertCatchError(e);
                }
              }
            });
        }
      });
    },
    deleteUser(e) {
      console.log(e);
      this.$confirm(
        `คุณต้องการลบแบบประเมินของ ${e.name} ${e.surname} หรือไม่?`,
        "Warning",
        {
          confirmButtonText: "ตกลง",
          cancelButtonText: "ยกเลิก",
          type: "warning",
          title: "ยืนยันการลบข้อมูล"
        }
      )
        .then(() => {
          HTTP.delete(`/complacency/${e.id}`)
            .then(res => {
              if (res.data.success) {
                this.changeSize(this.page);
                this.$message({
                  type: "success",
                  message: "ลบข้อมูลสำเร็จ"
                });
              } else {
                this.alertCatchError(res.data.data);
              }
            })
            .catch(e => {
              if (e == "Error: Request failed with status code 401") {
                this.checkAuth();
              } else {
                if (e != "Error: timeout of 120000ms exceeded") {
                  this.alertCatchError(e);
                }
              }
            });
        })
        .catch(() => {});
    },
    async changeSize(e) {
      let obj = {
        search: "",
        skip: 0,
        limit: this.limit
      };
      this.page = e;
      obj.skip = e == 1 ? 0 : (e - 1) * 10;
      obj.limit = this.limit;
      if (this.inputFilter == "") {
        obj.search = "all";
      } else {
        obj.search = this.inputFilter;
      }
      let res = await HTTP.get(
        `all/complcaency/${obj.search}/${obj.skip}/${obj.limit}`
      ).catch(e => {
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
        this.loading = false;
      });
      if (res.data.success) {
        this.tableUser = res.data.obj;
        this.feedTotal = res.data.total;
      }
    },
    async getAvg() {
      this.loading = true;
      this.tableData = [];
      let avg = 0;
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.get(`average/complacency`).catch(e => {
        console.log(e);
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            this.alertCatchError(e);
          }
        }
      });
      // console.log(res);
      if (res.data.success) {
        this.assessment.forEach((item, index) => {
          this.tableData.push({
            id: item.id,
            title: item.label,
            score: res.data.obj[this.assess[index]],
            date: res.data.obj.createdAt
          });
          avg += res.data.obj[this.assess[index]];
        });
        this.tableData.push({
          id: null,
          title: "คะแนนประเมินเฉลี่ยทั้งหมด",
          score: avg / 6,
          date: null
        });
      }
      setTimeout(() => {
        this.loading = false;
      }, 300);

      // console.log("getAvg", res, this.tableData);
    },
    async searchAssessment(time = 0) {
      this.skip = 0;
      this.currentPage = 1;
      clearTimeout(this.filterTime);
      this.filterTime = setTimeout(() => {
        this.getAssessment();
      }, time);
    },
    async getAssessment() {
      this.loading = true;
      if (this.inputFilter == "") {
        this.search = "all";
      } else {
        this.search = this.inputFilter;
      }
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      let res = await HTTP.get(
        `all/complcaency/${this.search}/${this.skip}/${this.limit}`
      ).catch(e => {
        if (e == "Error: Request failed with status code 401") {
          this.checkAuth();
        } else {
          if (e != "Error: timeout of 120000ms exceeded") {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
        }
        this.loading = false;
      });
      // console.log("getAssessment", res);
      if (res.data.success) {
        this.tableUser = res.data.obj;
        this.feedTotal = res.data.total;
      }
      this.tableData = [];

      this.loading = false;
    }
  }
};
</script>